@import '@styles/styles.scss';

.actionsDialogContent {
    .contentWrapper {
        margin-bottom: 40px;
        text-align: center;

        .headline {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title {
                margin-top: 0;
                margin-bottom: 20px;
            }
        }
    }

    @include mobile {
        .contentWrapper {
            .headline {
                .title {
                    margin-top: 0;
                    margin-bottom: 10px;
                }
            }
        }
    }
}
