@import '@styles/styles.scss';

/*=============================================
=                 Page Templates              =
=============================================*/
.pageWrapper {
    position: relative;

    .payProcessWrapper {
        &:before {
            position: fixed;
            height: 100vh;
            width: 30vw;
            top: 0;
            left: 0;
            content: ' ';
            background-color: $COLOR_TURQUOISE_GREEN;
            border-radius: 0 40px 40px 0;
            z-index: -1;
        }
    }

    &:before {
        position: fixed;
        height: 100vh;
        width: 30vw;
        top: 0;
        right: 0;
        content: ' ';
        background: 100% 50% / contain no-repeat
            url('../../assets/images/pageBackground/pageBkCircle.png');
        z-index: -1;
    }

    @include tablet {
        .payProcessWrapper {
            &:before {
                position: fixed;
                height: 30vh;
                width: 100vw;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                content: ' ';
                background-color: $COLOR_TURQUOISE_GREEN;
                border-radius: 0 0 40px 40px;
                z-index: -1;
            }
        }
        &:before {
            position: fixed;
            width: 100vw;
            height: 125vh;
            left: 0;
            right: 0;
            top: 45%;
            content: ' ';
            background: 0 / contain no-repeat
                url('../../assets/images/pageBackground/pageBkCircle.png');
            z-index: -1;
            transform: rotate(90deg);
        }
    }
}
.mainInfosWrapper {
    min-width: 800px;
    background-color: $COLOR_WHITE;

    @include tablet {
        min-width: auto;
    }
}
/*=====  End of Page Templates  ======*/

/*=============================================
=                 Buttons                     =
=============================================*/
.btnGroupWrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    margin-top: $SPACING_LAYOUT_MEDIUM;
    border-top: 1px solid $COLOR_SPANISH_GREY;

    @include mobile {
        justify-content: center;
    }
    .icon {
        display: none;
    }
}

.btn {
    & + .btn {
        margin-right: 20px;
        margin-left: 0;
    }

    &.MuiButton-root,
    &.MuiButton-contained,
    &.MuiButton-outlined {
        height: 64px;
        width: 248px;
        border-radius: 32px;
        &:hover {
            box-shadow: none;
        }

        @include mobileLarge {
            width: 220px;
        }
        @include mobile {
            width: 146px;
        }

        @include mobile {
            width: 100px;
        }

        &.btnPending {
            @include mobile {
                width: 146px;
            }
        }
    }

    &.MuiButton-contained {
        svg .fillArea {
            fill: $primaryTextContrast;
            stroke: $primaryTextContrast;
        }

        &.Mui-disabled {
            background-color: $primaryColor;
            color: $primaryTextContrast;
            opacity: 0.6;

            .MuiButton-label {
                color: $primaryTextContrast;
            }
        }
    }

    &.MuiButton-outlined {
        border: none;
        background-color: rgba(176, 176, 176, 0.04);

        &:hover {
            box-shadow: none;
            color: #b0b0b0;
        }

        .MuiButton-label {
            color: $COLOR_BLACK;
        }

        svg .fillArea {
            fill: $primaryColor;
        }
    }

    &.MuiButton-outlined {
        &:hover {
            border: none;
            background-color: $COLOR_LIGHTGREY;
        }
    }
}

.dialogActionGroup {
    margin-bottom: 50px;
    &.MuiDialogActions-root {
        display: flex;
        justify-content: center;
        flex-direction: row-reverse;
    }

    .icon {
        display: none;
    }
}

/*=====  End of Buttons  ======*/
.itemField.count,
.itemField.userPrice {
    display: none;
}
.itemField.unitCount,
.itemField.realAmount {
    .unit,
    .numeric {
        color: $primaryColor;
        line-height: 1;
    }
}

.qrBoardInfosWrapper {
    border: none;
    box-shadow: none;
}

.snackbarWrapper {
    &.MuiAlert-standardSuccess {
        background-color: $infosBkColor;
        border: 1px solid $COLOR_MIDGREY;
    }
}
