.dialogWrapper {
    .DialogInfo {
        position: relative;
        &:before {
            position: absolute;
            height: 100%;
            width: 35%;
            right: 0;
            content: ' ';

            background: center / contain no-repeat
                url('../../assets/images/pageBackground/pageBkCircle.png');
            opacity: 0.3;
        }
    }
    .MuiDialog-paper {
        max-width: 630px;
    }
}
