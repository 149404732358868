@import '@styles/styles.scss';

.orderInfos {
    background-color: $infosBkColor;
    margin-top: 20px;

    .infosTitle {
        font-size: $FONTSIZE_24;
        font-weight: bold;
        margin-bottom: 24px;
        color: $primaryColor;
        padding: 15px 0;
    }

    .infosWrapper {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-wrap: wrap;
        // padding: 0 25px 20px 25px;

        .rightInfos {
            &.fieldLabelWrapper {
                min-width: 20px;
            }
        }

        .buyUnit,
        .sellUnit {
            .fieldLabel {
                font-size: $FONTSIZE_18;
            }

            .field {
                font-weight: bold;
                font-size: $FONTSIZE_30;
                color: $COLOR_RED;
            }
        }
    }

    @include mobile {
        .infosTitle {
            padding: 10px 0;
            font-size: $FONTSIZE_18;
        }
    }
}
