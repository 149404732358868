@import '@styles/styles.scss';

.ItemPayMethodOption {
    height: 65.5px;
    width: 87px;
    border-radius: 8px;
    background-color: $primaryColor;
    border: none;

    .payMethodName {
        display: none;
    }
}
