@import '@styles/styles.scss';

.btnCopyWrapper {
    .txtCopy {
        color: $primaryColor;
        font-weight: $FONTWEIGHT_BOLD;
        text-decoration: underline;
    }

    .iconCopy {
        display: none;
    }

    .btn.btnCopy {
        margin: 0;
        &:hover {
            background-color: #c9c9c9;
        }
        &.MuiButton-text {
            color: $COLOR_BLACK;
            height: 26px;
            min-height: auto;
            width: 56px;
            min-width: 56px;

            @include mobile {
            }
        }
    }
}
