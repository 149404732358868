@import '@styles/styles.scss';

.confirmInfos {
    border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
        justify-content: flex-start;
    }

    .infosTitle {
        display: none;
    }
}
