@import '@styles/styles.scss';

.stepper.stepperVertical {
    margin: 80px -36px 0 0;
    .logoBrandWrapper {
        height: 180px;
    }
    .step {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 254px;
        height: 150px;

        .stepName {
            font-size: $FONTSIZE_20;
        }
        .stepItm {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: bold;
            height: 30px;
            width: 100%;
            z-index: 2;
            .stepIndex {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 16px;
                min-width: 45px;
                min-height: 45px;
                font-size: $FONTSIZE_24;
            }
        }

        .stepVerticalLine {
            width: 4px;
            height: 120px;
            margin-left: 16px;
            background-color: transparent;
        }

        &.currStep,
        &.completedStep {
            .stepItm {
                display: flex;
                justify-content: space-between;
                opacity: 1;
                width: 100%;
            }
        }

        &.completedStep {
            .stepVerticalLine {
                margin-right: 36px;
            }
        }
    }
}
