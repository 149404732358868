@import '@styles/styles.scss';

.stepper {
    .step {
        .stepItm {
            color: $primaryColor;
            .stepName {
                color: $COLOR_BLACK;
            }
            .stepIndex {
                border-radius: 50%;
                border: 5px solid $COLOR_GAINSBORO_GREY;
                color: $COLOR_BLACK;
                background-color: $COLOR_WHITE;
                line-height: 0;
            }
        }

        &.currStep {
            .stepIndex {
                color: $COLOR_BLACK;
                background-color: $COLOR_WHITE;
                border-color: $COLOR_DARK_PASTEL_GREEN;
            }
        }

        &.completedStep {
            .stepVerticalLine,
            .stepHorizontalLine {
                background-color: $COLOR_DARK_PASTEL_GREEN;
            }

            .stepIndex {
                color: $COLOR_WHITE;
                background-color: $COLOR_DARK_PASTEL_GREEN;
                border-color: $COLOR_DARK_PASTEL_GREEN;
            }
        }
    }
}
