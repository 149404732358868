@import '@styles/styles.scss';

.qrCodeBoardWrapper {
    .qrCodeImg {
        padding: 10px;
        background-color: $COLOR_WHITE;
        border: 1px solid #2a2323;
        box-shadow: 0 2px 10px 4px rgba(37, 39, 38, 0.05);
    }
}
