@import '@styles/styles.scss';

.stepper {
    &.stepperHorizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 768px;
        margin: 0 auto -25px;

        .stepWrapper {
            display: flex;
        }
        .step {
            min-width: 275px;
            display: flex;
            justify-content: center;
            .stepName {
                font-size: $FONTSIZE_24;
            }
            .stepItm {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex-direction: column;
                min-width: 180px;
                color: $primaryColor;
                text-align: center;

                .stepConnector {
                    z-index: 1;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 28px;
                }
                .stepIndex {
                    font-size: $FONTSIZE_28;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 56px;
                    min-height: 56px;
                }
            }

            .stepHorizontalLine {
                z-index: -1;
                position: absolute;
                left: calc(75%);
                width: 260px;
                height: 6px;
                background-color: transparent;
            }
        }

        @include tablet {
            .step {
                min-width: 250px;
                width: 250px;
            }
        }

        @include mobileLarge {
            margin-top: 0;
            margin-bottom: -30px;
            min-width: auto;
            .step {
                min-width: 175px;
                width: 175px;

                .stepItm {
                    min-width: 150px;
                    width: 150px;
                    .stepConnector {
                        margin-bottom: 5px;
                    }

                    .stepIndex {
                        font-weight: $FONTWEIGHT_BOLD;
                    }
                }

                .stepHorizontalLine {
                    width: 160px;
                    height: 5px;
                }
            }
        }

        @include mobile {
            .step {
                min-width: 100px;
                width: 100px;

                .stepItm {
                    font-size: $FONTSIZE_16;
                    .stepName {
                        font-size: $FONTSIZE_18;
                    }
                    .stepIndex {
                        font-size: $FONTSIZE_14;
                        border-width: 4px;
                        width: 36px;
                        height: 36px;
                        min-width: 36px;
                        min-height: 36px;
                    }
                    min-width: 70px;
                    width: 70px;
                    .stepConnector {
                        margin-bottom: 14px;
                    }
                }

                .stepHorizontalLine {
                    width: 80px;
                    height: 3px;
                }
            }
        }

        @include mobileSmall {
            .step {
                min-width: 80px;
                width: 80px;
                .stepItm {
                    min-width: auto;

                    .stepName {
                        font-size: $FONTSIZE_14;
                    }
                    .stepConnector {
                        margin-top: 10px;
                    }
                }
                .stepHorizontalLine {
                    width: 80px;
                }
            }
        }
    }
}
