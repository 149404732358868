@import '@styles/styles.scss';

.wrapper {
    padding: 65px 50px 0 65px;

    @include tablet {
        padding: 65px 50px 0 50px;
    }

    @include mobileLarge {
        padding: 30px $SPACING_LAYOUT_MEDIUM 0 $SPACING_LAYOUT_MEDIUM;
    }

    &.sellPaying,
    &.buyPaying {
        // .companyInfosWrapper {
        //     width: 50%;
        // }
        .infosGroupWrapper {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
        }

        @include tablet {
            .infosGroupWrapper {
                flex-direction: column;
            }
        }
    }

    &.buyPaying {
        .itemField {
            &.sellerName {
                .fieldLabelWrapper {
                    min-width: 115px !important;
                }
            }
        }
    }

    &.sellPayingTwoStepSimple,
    &.buyPayingManual {
        .amountInfosWrapper {
            width: 100%;
            margin-top: 0;
        }
    }

    &.buyPayingManual {
        .amountInfosWrapper {
            margin-top: 20px;
        }
    }
}
