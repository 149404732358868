@import '@styles/styles.scss';

.infosHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-bottom: 20px;
    padding: 50px;
    color: $COLOR_BLACK;

    .headline {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;

        .title {
            margin-bottom: 20px;
            font-size: $FONTSIZE_40;
            text-align: center;
            margin-top: 10px;
        }

        .iconHead {
            width: 64px;
            .fillArea {
                fill: $primaryColor;
                stroke: $primaryColor;
            }
        }
    }

    .message {
        font-size: $FONTSIZE_18;
        text-align: center;
    }

    @include mobile {
        padding: 20px 0;

        .headline {
            .title {
                font-size: $FONTSIZE_30;
            }

            .iconHead {
                width: 48px;
            }
        }
    }
}
