@import '@styles/styles.scss';

.itemField {
    .fieldValue {
        @include tablet {
            flex-wrap: nowrap;
        }

        @include mobile {
            flex-wrap: wrap;
        }
    }
    &.sellerName {
        .fieldExtra {
            margin-top: 8px;

            @include tablet {
                margin-top: 0;
            }
        }

        .itemTags {
            .itemTag {
                margin-right: 0;
                margin-bottom: 0;
            }
        }
    }
}
